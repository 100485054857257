import { render, staticRenderFns } from "./addCargoType.vue?vue&type=template&id=543b6164"
import script from "./addCargoType.vue?vue&type=script&lang=js"
export * from "./addCargoType.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports