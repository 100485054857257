import { Message } from "element-ui";
import axios from "axios";

let timeout = null,
  wsImpl = window.WebSocket || window.MozWebSocket;
export function antiShake(callback, time) {
  time || (time = 1000);
  clearTimeout(timeout);
  if (!timeout) {
    callback && callback();
  }
  timeout = setTimeout((_) => {
    timeout = null;
  }, time);
}
export function wsReadCard() {
  window.ws = new wsImpl("ws://127.0.0.1:7181/");
  ws.onopen = (_) => {
    // this.$message.success("提示:开启");
    console.log("=====开启");
  };
  ws.onclose = () => {
    // this.$message.info("提示:关闭");
    console.log("======关闭");
  };
  ws.onerror = (err) => {
    console.log(err);
  };
  ws.onmessage = (evt) => {
    let num = evt.data.toLocaleLowerCase();
    this.idcard = num;
    ws.close();
  };
}
export function printExcel(url, name, select, method) {
  method || (select.page && delete select.page);
  method || (select.limit && delete select.limit);

  select.endtime && (select.endTime = select.endtime);
  select.starttime && (select.startTime = select.starttime);
  if(url!="/driver/epidemicprevention/exportlistdetails"){
	   select.status && (select.statuss = select.status);
	   select.status && delete select.status;
  }
  select.endtime && delete select.endtime;
  select.starttime && delete select.starttime;
  

  !method && (method = "post");
  antiShake(async (_) => {
    const { data } = await this.$http("api1", url, method, select);
	console.log(data);
    const blob = new Blob([data], { type: "application/vnd.ms-excel" });
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = URL.createObjectURL(blob);
    a.download =name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  });
}

let conn,
  timer = 0;
export function getConnection() {
  //十分钟后关闭连接。
  function close_connection(conn) {
    return setTimeout(() => {
      conn.close();
    }, 10 * 60 * 1000);
  }

  return new Promise((resolve, reject) => {
    if (conn && conn.readyState == wsImpl.OPEN) {
      if (timer) {
        clearTimeout(timeout);
      }
      timer = close_connection(conn);
      resolve(conn);
    } else {
      conn = new wsImpl("ws://127.0.0.1:7181/");

      conn.addEventListener("open", function(e) {
        timer = close_connection(conn);
        resolve(conn);
      });

      conn.addEventListener("close", (e) => {
        console.log("conn is closeed");
      });
      conn.addEventListener("error", function(e) {
        console.log("error", e);
        conn = null;
        Message.error(
          "读卡器连接失败，请确认服务是否开启，读卡器是否正常工作。"
        );
        reject(e);
      });
    }
  });
}

export function readRFIDcard_new() {
  return axios
    .post(
      "http://127.0.0.1:20085/moduleapi/syncinventory",
      {
        antennas: [1],
        timeout: 300,
      }
      // ,
      // {
      //   headers: {
      //     "Reporting-Endpoints": "default='https://https://yhwlps.com'",
      //     "Report-To": {
      //       group: "default",
      //       max_age: 86400,
      //       endpoints: [{ url: "https://yhwlps.com" }],
      //     },
      //   },
      // }
    )
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => {
      console.log("error", error);
      Message.error("读卡错误！请检查读卡器连接是否正常,服务程序是否开启。");
      throw error;
    });
}