<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_table">
        <el-row style="height: 100%">
          <el-col :span="8">
            <div class="tree-header">
              <span>货物名称</span>
              <el-button
                size="small"
                type="primary"
                @click="handleAddType('', 1)"
                >添加货物</el-button
              >
            </div>
            <el-row style="padding: 10px 5px">
              <el-input
                size="small"
                placeholder="输入关键字进行过滤"
                v-model="filterText"
              >
              </el-input>
            </el-row>
            <el-tree
              ref="treeRef"
              :data="treeData"
              :props="defaultProps"
              @node-click="handleNodeClick"
              :filter-node-method="filterNode"
              default-expand-all
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span>
                  <el-button
                    type="text"
                    size="mini"
                    v-show="data.levelTag != 3"
                    @click.stop="
                      handleAddType(data.id, parseInt(data.levelTag) + 1)
                    "
                  >
                    新增
                  </el-button>
                  <el-button
                    type="text"
                    size="mini"
                    @click.stop="handleEditType(data)"
                  >
                    修改
                  </el-button>
                  <el-button
                    type="text"
                    size="mini"
                    @click.stop="handleDeleteType(data.id)"
                    style="color: rgb(254, 90, 36)"
                  >
                    删除
                  </el-button>
                </span>
              </span></el-tree
            >
          </el-col>
          <el-col :span="16">
            <div class="tree-header">
              <span>货物名称</span>
              <el-button
                v-show="currentTreeNode"
                size="small"
                type="primary"
                @click="handleAddName"
                >添加货名</el-button
              >
            </div>
            <el-table max-height="calc(100% - 30px)" :data="tableData">
              <el-table-column
                show-overflow-tooltip
                label="序号"
                type="index"
                width="50px"
              ></el-table-column>
              <el-table-column
                show-overflow-tooltip
                label="货名"
                prop="cargoName"
              ></el-table-column>
              <el-table-column label="操作">
                <template v-slot="scope">
                  <span
                    class="iconfont icon-xiugai"
                    @click="handleEditName(scope.row)"
                  ></span>
                  <span
                    class="iconfont icon-shanchu"
                    @click="handleDeleteName(scope.row.id)"
                  ></span>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <AddCargoType
        v-if="showAddCargoType"
        ref="addCargoTypeRef"
        @confirm="handleTreeConfirm"
        @cancel="showAddCargoType = false"
      ></AddCargoType>
      <AddCargoName
        v-if="showAddCargoName"
        ref="addCargoNameRef"
        @confirm="handleTableConfirm"
        @cancel="showAddCargoName = false"
      ></AddCargoName>
    </div>
  </div>
</template>
<script>
import mTitle from "@/components/mTitle";
import { antiShake } from "@/plugins/other.js";
import AddCargoType from "./addCargoType.vue";
import AddCargoName from "./addCargoName.vue";
export default {
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "label",
      },
      filterText: "",
      showAddCargoType: false,
      treeData: [],
      currentTreeNode: null,
      showAddCargoName: false,
      tableData: [],
    };
  },
  // computed: {
  //   ...mapState(["conditionsList"]),
  // },
  components: {
    mTitle,
    AddCargoType,
    AddCargoName,
  },
  created() {
    this.getTree();
  },
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    },
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleAddType(pid, levelTag) {
      this.showAddCargoType = true;
      this.$nextTick(() => {
        this.$refs.addCargoTypeRef.isEdit = false;
        this.$refs.addCargoTypeRef.pid = pid;
        this.$refs.addCargoTypeRef.levelTag = levelTag;
      });
    },
    async getTree() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/CargoTypeInfo/cargoTypeTreeList",
        "get"
      );
      console.log(data);
      if (data.code != 0) return this.$message.error(data.msg);
      this.treeData = data.data;
    },
    handleTreeConfirm() {
      this.showAddCargoType = false;
      this.getTree();
    },
    handleEditType(data) {
      this.showAddCargoType = true;
      this.$nextTick(() => {
        this.$refs.addCargoTypeRef.isEdit = true;
        this.$refs.addCargoTypeRef.id = data.id;
        this.$refs.addCargoTypeRef.form.cargoName = data.label;
      });
    },
    handleDeleteType(id) {
      this.$messageBox
        .confirm("确认删除该货类, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/CargoTypeInfo/delete",
            "post",
            {
              id: id,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.getTree();
          }
        });
    },
    handleNodeClick(data) {
      this.getTable(data.id);
      this.currentTreeNode = data;
    },
    async getTable(id) {
      const { data } = await this.$http(
        "api1",
        "/api/biz/CargoTypeInfo/cargoNameList",
        "get",
        {
          pid: id,
        }
      );
      console.log(data);
      if (data.code != 0) return this.$message.error(data.msg);
      this.tableData = data.data;
    },
    handleAddName() {
      this.showAddCargoName = true;
      this.$nextTick(() => {
        this.$refs.addCargoNameRef.isEdit = false;
        this.$refs.addCargoNameRef.pid = this.currentTreeNode.id;
        this.$refs.addCargoNameRef.levelTag =
          parseInt(this.currentTreeNode.levelTag) + 1;
      });
    },
    handleTableConfirm() {
      this.showAddCargoName = false;
      this.getTable(this.currentTreeNode.id);
    },
    handleEditName(data) {
      this.showAddCargoName = true;
      this.$nextTick(() => {
        this.$refs.addCargoNameRef.isEdit = true;
        this.$refs.addCargoNameRef.id = data.id;
        this.$refs.addCargoNameRef.form.cargoName = data.cargoName;
      });
    },
    handleDeleteName(id) {
      this.$messageBox
        .confirm("确认删除该货名, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/CargoTypeInfo/delete",
            "post",
            {
              id: id,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.getTable();
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.tree-header {
  width: 100%;
  display: flex;
  padding: 5px 10px 0 10px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  span {
    font-weight: bold;
  }
}
</style>
